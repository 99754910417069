.mail_white {
        color: $page_title_color;
    }

.sidenav {
    transform: translateX(-100%);
    width:100%;
    height: 100%;
    position: fixed;
    z-index: 2;
    top: 80px;
    left: 0;
    background: rgba(165,201,0,0.90);
    overflow-x: hidden;
    padding-top: 60px;
    transition: 0.4s ease-out;

    a {
        font-weight:600;
        font-size: 2.0em;
        color: $page_title_color;
        letter-spacing: 0;
        line-height: 68px;
        display: block;
        transition: 0.6s;
        @media screen and (max-width: $screen-xs-max) {
            font-size: 1.5em;
        }
    }

    &.open {
        transform: translateX(0)
    }
}

.sidenav a:hover, .offcanvas a:focus{
    color: $dark_green;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;


}
