.logo_image {
	max-width: 220px;
	max-height: 120px;
	margin-top:20px;
	border: solid 1px transparent;

	&:hover {
		border: solid 1px $blue_l;
	}
}
