.findUs {
	.map_img {


	}
	.tel {


		font-size: 30px;
		color: $blue_l;
		letter-spacing: 0;

	}
	.email {


		font-size: 20px;
		color: $blue_l;
		letter-spacing: 0;
	}
	.phone_green, .mail_green {

		color: $green;

	}


	p {
		font-weight:500;
		font-size: 20px;
		color: #000000;
		letter-spacing: 0;
		line-height: 31px;
	}
}
h1 {

		font-weight:600;
		font-size: 36px;
		color: #004566;
		letter-spacing: 0;
	}


