.footer_branch, {

	h2 {
		text-transform: uppercase;
		font-weight:600;
		font-size: 18px;
		color: $light_blue2;

	}
	a.tel {
		font-size: 16px;
		color: $dark_green;
	}
	p {
		font-weight:500;
		font-size: 16px;
		color: $page_title_color;
		letter-spacing: 0;
		line-height: 22px;
		margin-top: 5px;
		margin-bottom: 20px;
	}

	.fa-map-marker {
		color:#FF4D4D;
	}

}
