.contactUsmenu {
    left: 0%;
    height: 100%;
    background-color: rgba(0, 170, 250, 0.5);
    position: absolute;
    overflow-x: hidden;
    z-index: 1;
    background-image: linear-gradient(rgba(0,169,250,0.80) 0%, rgba(0,69,102,0.8) 98%);
    transition:0.8s ease-out;
    transform:translateY(-100%);

    &.show {
        transform:translateY(0);
    }

    h1 {
        margin-top: 80px;
        font-weight:600;
        font-size: 2.0em;
        color: #FFFFFF;
        letter-spacing: 0;
        text-shadow: 0 2px 4px rgba(0,0,0,0.50);
    }
    p {

        font-weight:300;
        font-size: 1.5em;
        color: #FFFFFF;
        letter-spacing: 0;
        text-shadow: 0 2px 4px rgba(0,0,0,0.50);
    }
    button {
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
        color: $button_background;
        font-weight:400;
        opacity: 0.8;
        border: 2px solid $button_background;
        border-radius: 4px;
        background-color: Transparent;
        height: 40px;
        width:180px;
        margin-top: 10px;

        &:hover, &:focus{
            background: $blue_l;
            color: $page_title_color;
        }

        &:active {
            background: $green;
            color: $page_title_color;
        }

    }

}
