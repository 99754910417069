.navButton {
	color: $button_background;
	background-image: linear-gradient($blue_l 0%, $blue_d 100%);
	border: 1px solid $dark_green;
	margin-top: 40px;
	font-size: 0.9em;
	text-transform:uppercase;
	height:50%;


	&:hover {
		background: $green;
		color: $page_title_color;
	}

	&:active {
		background: $green;
		color: $page_title_color;
	}
}
img {
	margin-top: 10px;
	margin-left: 3px;
}
.header_menu_button_boxes {
	display: block;
	text-align: center;
	a {
		margin:0 10px;
		text-decoration: none;
	}
}

@media screen and (max-width: $screen-sm-min) {
	.navButton {
		font-size: 1.3em;
	}
}


@media screen and (max-width: 995px) {
	.header_menu_button_boxes {
		display:none;
	}
}
