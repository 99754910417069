.page_title {
	font-size: 36px;
	color: $page_title_color;
	letter-spacing: 0;
	line-height:90px;
.fa {
	line-height:90px;
}
}
.page_title_box {
	height: 90px;
	background: $green;
	margin-top: 20px;

}
.blue {
	background: $blue_d !important
}
.center {
	text-align: center;
}


