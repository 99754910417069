#contact_form {
    .checkbox_holder {
        margin-right:20px;
    }

    .declaration label {
        font-size:0.85em;
    }

    input,select,textarea {
        border:#00AAFA solid 1px;
    }

    #submit {
        color: #00AAFA;
        font-weight: 600;
        opacity: 0.8;
        border: 2px solid #00AAFA;
        border-radius: 4px;
        background: #FDFEFE;
        height: 40px;
        width: 180px;
        margin-top: 10px;

        &:hover, &:focus {
            background: #00AAFA;
            color: #FFFFFF;
        }
    }

    label {
        font-weight: normal;
    }
}


.map {
    height: 340px;
    width:100%;
}