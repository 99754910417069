.header_image {

	max-width: 1200px;
    height: 340px;
    max-height: 340px;
	overflow: hidden;
	box-sizing: border-box;
	background-image: url('http://www.themortgagehut.co.uk/media/img/pages_images/index@2x.jpg');
	background-size:cover;
}

