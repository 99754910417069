.front_page_row {
	font-weight:500;
	font-size: 18px;
	line-height: 23px;

	h2 {
		text-align: center;
		font-weight:600;
		font-size: 36px;
		color: $blue_d;
		margin:20px;
	}

	button {
		color: $blue_l;
		font-weight:600;
		opacity: 0.8;
		border: 2px solid $blue_l;
		border-radius: 4px;
		background: $button_background;
		height: 40px;
		width:180px;
		margin-top: 10px;

		&:hover, &:focus{
			background: $blue_l;
			color: $page_title_color;
		}

		&:active {
			background: darken($blue_l, 10%);
			color: $page_title_color;
		}
	}
}
.img-left {
			float: left;
		}
.img-right {
			float: right;
		}
.white {
	color: $page_title_color;
}
.arrow {
	&:hover, &:focus{
            color: darken($page_title_color, 10);
        }
}


