
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "menu";
@import "header";
@import "menu_button";
@import "header_image";
@import "logo_image";
@import "nav_bar";
@import "page_title";
@import "front_page_row";
@import "footer";
@import "footer_branch";
@import "contactUs_Menu";
@import "find_Us";
@import "form";

body {
	margin-top:80px;
}

.fa_white {
	color: white;
}
.fa_green {
	color: $dark_green;
}

@media screen and (max-width: $screen-sm-min) {
.front_page_row {
	text-align: center;
}

}
